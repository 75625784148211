.stop {
  margin: 0 11px;

  @media (min-width: 768px) {
    margin: 0 48px;
  }
}

.header-wrap {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1600px;
    margin: 0 auto;

    .logo {
      width: 110px;
      z-index: 100;

      // filter: drop-shadow(0 0 25px #DF832F);

      @media (min-width: 768px) {
        width: 140px;
      }
    }
  }
}

.banner {
  display: flex;
  position: relative;
  color: white;
  width: 100%;
  top: 0;
  left: 0;
  padding: 11px;
  background-color: rgb(219, 161, 107);
}

.navigation {
  list-style-type: none;

  .links {
    margin-right: 10px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.11px;

    @media (min-width: 960px) {
      font-size: 14px;
      line-height: 24px;
      margin-right: 12px;
    }

    @media (min-width: 1280px) {
      margin-right: 24px;
      text-align: center;
      font-size: 16px;
      letter-spacing: 0.22px;
      line-height: 43px;
    }

    @media (min-width: 1600px) {
      font-size: 18px;
      margin-right: 32px;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      text-decoration: none !important;
      color: #fff;
      transition: all 300ms ease-in-out;

      &:hover {
        color: #df832f;
      }
    }

    .special {
      background-image: linear-gradient(to right, #df832f 0, #cb9b51 22%, #f6e27a 45%, #df832f 50%, #f6e27a 55%, #cb9b51 78%, #df832f 100%);
      color: transparent;
      -webkit-background-clip: text;
    }
  }
}

.connect-wallet-2 {
  box-sizing: border-box;
  border: 0;
  border-image-source: linear-gradient(to right, #df832f 30%, #dd9450 100%);
  border-image-slice: 4;
  overflow: hidden;
  cursor: pointer;
}

.connect-wallet {
  box-sizing: border-box;
  border: 0;
  border: 1px solid #008000;
  border-image-source: linear-gradient(to right, #df832f 30%, #dd9450 100%);
  border-image-slice: 4;
  overflow: hidden;
  cursor: pointer;
  display: none;

  // @media (min-width: 1024px) {
  //   display: block;
  // }

  // @media (min-width: 1280px) {
  //   padding: 10.43px 25px;
  //   font-weight: 500;
  //   font-size: 16px;
  //   position: relative;
  //   line-height: 27px;
  //   letter-spacing: 4px;
  // }

  &::after {
    position: absolute;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    content: '';
    width: 0;
    bottom: -1px;
    background: linear-gradient(to right, #ed00c9 30%, #bd00ed 100%);
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: -1;

    &:hover {
      width: 120%;
    }
  }
}

.loader-io {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  inset: 0;
  z-index: 100;
  width: 100%;
  min-height: 100vh;

  .circle {
    width: 32px;
    height: 32px;
    animation: zoom-in-zoom-out 1.5s ease-out infinite;
  }

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.5, 1.5);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}

.mobile-btn {
  font-size: 14px;
  line-height: 14px !important;
  font-weight: 600;
  letter-spacing: 0.84px;
  color: #fff;
  text-shadow: 0 0 4px #0000004d;
  height: 42px;
  padding: 12.64px 31px;
  box-sizing: border-box;
  background: transparent linear-gradient(90deg, #df832f 0%, #df832f 100%) 0% 0% no-repeat padding-box;
  position: relative;
  border: 1px solid #008000;
  border-image-source: linear-gradient(to right, #df832f 30%, #dd9450 100%);
  border-image-slice: 4;
  clip-path: polygon(6% 0, 80% 0%, 100% 0, 100% 77%, 94% 100%, 20% 100%, 0 100%, 0 25%);
  overflow: hidden;
  cursor: pointer;

  &::after {
    position: absolute;
    transition: 0.3s;
    content: '';
    width: 0;
    bottom: -1px;
    background: linear-gradient(to right, #df832f 30%, #df832f 100%);
    clip-path: polygon(6% 0, 80% 0%, 100% 0, 100% 77%, 94% 100%, 20% 100%, 0 100%, 0 25%);
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: -1;

    &:hover {
      width: 120%;
    }
  }

  .line1 {
    width: 24px;
    height: 1px;
    position: absolute;
    background: #df832f;
    top: 3px;
    left: -4px;
    transform: rotate(-39deg);

    @media (min-width: 1024px) {
      top: 6px;
      left: -6px;
      transform: rotate(-45deg);
    }
  }

  .line2 {
    width: 24px;
    height: 1px;
    position: absolute;
    background: #df832f;
    bottom: 3px;
    right: -6px;
    transform: rotate(-41deg);

    @media (min-width: 1024px) {
      bottom: 6px;
      right: -6px;
      transform: rotate(-42deg);
    }
  }
}

.top-bg {
  position: fixed;
  transition: all 300ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
  inset: 0;
  width: 100%;
  height: 100%;
  background: url('../../../backgroundimage/noise.png'), #522e0c;

  .bg-statue {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .inner-tab {
    padding: 8px 20px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top-navigation {
      display: flex;
      padding: 16px 20px;
      top: 0;
      position: absolute;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .logo-2 {
        max-width: 97px;
        filter: drop-shadow(0 0 25px #a0008f);
      }
    }

    .bottom-navigation {
      position: relative;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      ul {
        list-style: none;

        li {
          font-size: 17px;
          line-height: 45px;
          letter-spacing: 0.2px;
          text-align: center;
          color: #fff;
          text-decoration: none !important;
        }
      }

      .mobile-btn {
        font-size: 14px;
        line-height: 14px !important;
        margin-top: 20px;
        font-weight: 600;
        letter-spacing: 0.84px;
        color: #fff;
        text-shadow: 0 0 4px #0000004d;
        height: 42px;
        padding: 12.64px 31px;
        box-sizing: border-box;
        background: transparent linear-gradient(90deg, #df832f 0%, #df832f 100%) 0% 0% no-repeat padding-box;
        position: relative;
        border: 1px solid #008000;
        border-image-source: linear-gradient(to right, #df832f 30%, #dd9450 100%);
        border-image-slice: 4;
        clip-path: polygon(6% 0, 80% 0%, 100% 0, 100% 77%, 94% 100%, 20% 100%, 0 100%, 0 25%);
        overflow: hidden;
        cursor: pointer;

        &::after {
          position: absolute;
          transition: 0.3s;
          content: '';
          width: 0;
          bottom: -1px;
          background: linear-gradient(to right, #df832f 30%, #df832f 100%);
          clip-path: polygon(6% 0, 80% 0%, 100% 0, 100% 77%, 94% 100%, 20% 100%, 0 100%, 0 25%);
          height: 120%;
          left: -10%;
          transform: skewX(15deg);
          z-index: -1;

          &:hover {
            width: 120%;
          }
        }

        .line1 {
          width: 24px;
          height: 1px;
          position: absolute;
          background: #df832f;
          top: 3px;
          left: -4px;
          transform: rotate(-39deg);

          @media (min-width: 1024px) {
            top: 6px;
            left: -6px;
            transform: rotate(-45deg);
          }
        }

        .line2 {
          width: 24px;
          height: 1px;
          position: absolute;
          background: #df832f;
          bottom: 3px;
          right: -6px;
          transform: rotate(-41deg);

          @media (min-width: 1024px) {
            bottom: 6px;
            right: -6px;
            transform: rotate(-42deg);
          }
        }
      }
    }
  }
}

.top-minus {
  top: -170%;
  visibility: hidden;
  z-index: -1;
}

.doc-link {
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #df832f;
  }
}
